@font-face {
    font-family: 'Cabinet Grotesk';
    src: url('../fonts/CabinetGrotesk/CabinetGrotesk-Black.eot');
    src: url('../fonts/CabinetGrotesk/CabinetGrotesk-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Black.woff2') format('woff2'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Black.woff') format('woff'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Black.ttf') format('truetype'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Black.svg#CabinetGrotesk-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cabinet Grotesk';
    src: url('../fonts/CabinetGrotesk/CabinetGrotesk-Bold.eot');
    src: url('../fonts/CabinetGrotesk/CabinetGrotesk-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Bold.woff2') format('woff2'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Bold.woff') format('woff'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Bold.ttf') format('truetype'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Bold.svg#CabinetGrotesk-Bold') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cabinet Grotesk';
    src: url('../fonts/CabinetGrotesk/CabinetGrotesk-Extrabold.eot');
    src: url('../fonts/CabinetGrotesk/CabinetGrotesk-Extrabold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Extrabold.woff2') format('woff2'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Extrabold.woff') format('woff'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Extrabold.ttf') format('truetype'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Extrabold.svg#CabinetGrotesk-Extrabold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cabinet Grotesk';
    src: url('../fonts/CabinetGrotesk/CabinetGrotesk-Extralight.eot');
    src: url('../fonts/CabinetGrotesk/CabinetGrotesk-Extralight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Extralight.woff2') format('woff2'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Extralight.woff') format('woff'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Extralight.ttf') format('truetype'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Extralight.svg#CabinetGrotesk-Extralight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cabinet Grotesk';
    src: url('../fonts/CabinetGrotesk/CabinetGrotesk-Light.eot');
    src: url('../fonts/CabinetGrotesk/CabinetGrotesk-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Light.woff2') format('woff2'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Light.woff') format('woff'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Light.ttf') format('truetype'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Light.svg#CabinetGrotesk-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cabinet Grotesk';
    src: url('../fonts/CabinetGrotesk/CabinetGrotesk-Medium.eot');
    src: url('../fonts/CabinetGrotesk/CabinetGrotesk-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Medium.woff2') format('woff2'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Medium.woff') format('woff'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Medium.ttf') format('truetype'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Medium.svg#CabinetGrotesk-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cabinet Grotesk';
    src: url('../fonts/CabinetGrotesk/CabinetGrotesk-Regular.eot');
    src: url('../fonts/CabinetGrotesk/CabinetGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Regular.woff2') format('woff2'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Regular.woff') format('woff'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Regular.ttf') format('truetype'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Regular.svg#CabinetGrotesk-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cabinet Grotesk';
    src: url('../fonts/CabinetGrotesk/CabinetGrotesk-Thin.eot');
    src: url('../fonts/CabinetGrotesk/CabinetGrotesk-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Thin.woff2') format('woff2'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Thin.woff') format('woff'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Thin.ttf') format('truetype'),
        url('../fonts/CabinetGrotesk/CabinetGrotesk-Thin.svg#CabinetGrotesk-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

:root {
    --default-white: #FFF5E6;
    --default-red: #EC202A;
    --default-yellow: #FEA018;
    --default-black: #121210;
    --default-black-low: rgba(18, 18, 16, 0.25);
    --default-black-half: rgba(18, 18, 16, 0.5);
    --normal-white: #fff;
}

* {
    margin: 0px;
    padding: 0px;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html,
body {
    background: var(--default-white);
}

body,
input,
textarea,
select {
    font-family: 'Cabinet Grotesk', Arial, Helvetica, sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    color: var(--default-white);
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-style: normal;
    font-weight: normal;
}

input[type="checkbox"],
input[type="radio"] {
    display: none;
}

img {
    max-width: 100%;
    border-style: none;
}

p {
    line-height: 1.32;
}

label {
    cursor: pointer;
}

a,
button {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    transition: all 0.4s ease;
    font-family: inherit;
    font-size: inherit;
    background-color: transparent;
}

a:active {
    background-color: transparent;
}

a {
    display: inline-block;
}

ul,
li {
    list-style: none;
}

.disabled-link {
    opacity: 0.4;
}

select,
map,
area,
a,
button {
    border: 0px;
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}

h1,
h2,
h3,
h4 {
    font-family: 'Cabinet Grotesk';
}

input[type=text],
input[type=password],
textarea {
    text-align: left;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

select::-ms-expand {
    display: none;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input::-ms-clear {
    display: none;
}

input[type="password"]::-ms-clear,
input[type="password"]::-ms-reveal {
    display: none;
}

input,
textarea,
select {
    border: 0;
    outline: none;
    line-height: normal;
    -webkit-appearance: none;
    border-radius: 0;
}

textarea {
    resize: none;
}

.c {
    margin: auto;
    padding: 0 60px;
    max-width: 1646px;
}

.clear {
    clear: both;
}

body>iframe {
    display: none !important;
}

.content {
    position: relative;
    min-height: calc(100vh - 713px);
}

.viewport.lock {
    position: fixed;
    width: 100%;
    left: 0;
}

.viewport {
    overflow: hidden;
}

.disabledLink {
    opacity: 0.4;
}

.noPad {
    padding: 0;
}

.middleWrap {
    white-space: nowrap;
    text-align: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.middle {
    vertical-align: middle;
    display: inline-block;
    white-space: normal;
}

.middleWrapAbs {
    white-space: nowrap;
}

.middleWrapTable {
    display: table;
    width: 100%;
    vertical-align: middle;
    position: relative;
}

.middleWrapTable .middle {
    display: table-cell;
}

.middleWrap:after,
.middleWrapAbs:after,
.thank:after,
.videoOverlay:after {
    content: "";
    vertical-align: middle;
    display: inline-block;
    width: 1px;
    height: 100%;
}

.image {
    position: relative;
    display: block;
}

.bsz,
.bsz .bgimage {
    background: no-repeat 50% 50%;
    overflow: hidden;
    background-size: cover;
}

.bsz .bgimage {
    opacity: 1;
    transition: opacity .8s;
}

.bsz.loading {
    background-color: var(--default-black);
}

.bsz.loading .bgimage {
    opacity: 0;
}

.bsz img {
    display: none;
    position: relative;
}

.bsz,
.bsz .bgimage,
.middleWrapAbs,
.overlay,
.thank,
.fullDimension {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.fullDimensionInner {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
}

.common_link,
:global(.common_link) {
    color: var(--default-yellow);
    border-bottom: 1px solid;
    transition: border 0.4s;
}

.common_link:hover,
:global(.common_link:hover) {
    border-bottom-color: transparent;
}

.fadeInAnimation {
    animation: fadeIn 0.8s ease-in;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

body.overflowHidden {
    overflow-y: hidden;
}

.darktangerine,
:global(.darktangerine) {
    color: var(--default-yellow);
}

::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: var(--default-black);
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: var(--default-black);
    opacity: 1;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: var(--default-black);
    opacity: 1;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--default-black);
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--default-black);
}

::placeholder {
    /* Most modern browsers support this now. */
    color: var(--default-black);
}

.field_row {
    padding-top: 15px;
    position: relative;
}

.field_row:first-child {
    padding-top: 0;
}

.field_row input,
.field_row textarea,
.field_row button,
.field_row .button {
    background: var(--default-white);
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.23;
    letter-spacing: 0;
    color: var(--default-black);
    padding: 14px 15px;
    width: 100%;
}

.field_row textarea {
    height: 150px;
}

.field_row.text {
    text-align: center;
    font-size: 18px;
    line-height: 1.5;
}

.submit {
    text-align: center;
}

.submit button,
.submit .button {
    background-color: var(--default-yellow);
    min-width: 285px;
    width: auto;
    transition: background 0.4s;
    position: relative;
}

/* == Toastify == */
:global .Toastify__toast {
    max-height: none;
    min-height: 0;
}

:global .Toastify__toast-body {
    margin: 0;
    padding: 0 6px;
    line-height: 1;
}

:global .Toastify__toast--default {
    background: var(--default-black) !important;
    color: var(--normal-white) !important;
    font-size: 16px !important;
    padding: 15px !important;
}

:global .Toastify__toast--default.success {
    background: var(--default-yellow) !important;
}

:global .Toastify__toast-container--top-right {
    top: 100px !important;
    right: 20px !important;
}

:global .Toastify__close-button--default {
    color: var(--normal-white) !important;
    opacity: 1 !important;
}

:global body .Toastify__toast-container {
    z-index: 100000;
}

:global .Toastify__close-button--light {
    color: var(--normal-white) !important;
    opacity: 1 !important;
}

/* == Toastify == */

/* C Editor CSS */
.cEditor {
    padding-top: 60px;
    padding-bottom: 60px;
}

.cEditor p,
.cEditor ol,
.cEditor ul,
.cEditor>div {
    margin-top: 30px;
    font-size: 18px;
}

.cEditor td p {
    margin-top: 0;
}

.cEditor.black-paragraph p {
    color: #000000;
}

.cEditor ol,
.cEditor ul {
    position: relative;
    list-style: none !important;
    /* !important added for override inline editor css */
}

.cEditor ul ul,
.cEditor ol ol,
.cEditor ol li,
.cEditor ul li {
    margin-top: 20px;
    position: relative;
}

.cEditor ul ul,
.cEditor ul li {
    padding-left: 30px;
}

.cEditor ol li:first-child,
.cEditor ul li:first-child {
    margin-top: 0;
}

.cEditor ul li::before {
    position: absolute;
    left: 0;
    top: 10px;
    width: 8px;
    height: 3px;
    content: '';
    background-color: var(--default-yellow);
}

.cEditor ol {
    counter-reset: item;
}

.cEditor ol li {
    counter-increment: item;
    display: table;
}

.cEditor ol li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 15px;
    color: var(--default-yellow);
    font-size: 18px;
}

.cEditor>*:first-child {
    margin-top: 0;
}

.cEditor h1,
.cEditor h2,
.cEditor h3,
.cEditor h4 {
    /* font-weight: 600; */
    /* line-height: 0.92; */
    margin-top: 20px;
}

.cEditor h1 {
    font-size: 72px;
}

.cEditor h2 {
    font-size: 60px;
}

.cEditor h3 {
    font-size: 50px;
}

.cEditor h4 {
    font-size: 21px;
}

.cEditor h5 {
    font-size: 20px;
}

.cEditor h6 {
    font-size: 17px;
}

.cEditor h5,
.cEditor h6 {
    font-weight: 600;
    color: var(--default-yellow);
    line-height: 1.1;
    margin-top: 20px;
}

.cEditor.short {
    max-width: 875px;
}

.cEditor a {
    color: var(--default-yellow);
    border-bottom: 1px solid;
}

.has-no-touchevents .cEditor a:hover {
    border-bottom-color: transparent;
}

.cEditor h1+p,
.cEditor h2+p,
.cEditor h3+p,
.cEditor h4+p,
.cEditor h5+p,
.cEditor h6+p,
.cEditor h1+ol,
.cEditor h2+ol,
.cEditor h3+ol,
.cEditor h4+ol,
.cEditor h5+ol,
.cEditor h6+ol,
.cEditor h1+ul,
.cEditor h2+ul,
.cEditor h3+ul,
.cEditor h4+ul,
.cEditor h5+ul,
.cEditor h6+ul {
    margin-top: 20px;
}

.cEditor iframe {
    max-width: 100%;
}

.inline-video,
.external-video {
    position: relative;
}

.inline-video .sizer {
    padding-top: 56.25%;
}

.external-video iframe,
.inline-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.cEditor table {
    margin-top: 30px;
}

.cEditor img {
    width: auto;
    height: auto;
    max-width: 100%;
}

.cEditor table {
    border: 1px solid #F2F1EF;
}

.cEditor table tr:nth-child(odd) {
    background: #F2F1EF;
}

.cEditor table td {
    padding: 14px 20px;
    height: auto !important;
}

.cEditor table tr {
    transition: all 0.4s;
}

.has-no-touchevents .cEditor table tr:hover {
    background: #F2F1EF;
}

/* C Editor CSS */
.teamlist_editor.cEditor {
    padding: 0;
}

/* Social Share section begin */
.social_share_div_row {
    width: 48px;
    position: absolute;
    border-radius: 5px 0px 0px 5px;
    filter: drop-shadow(0px 2px 10px rgba(18, 18, 16, 0.37));
    padding-bottom: 10px;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    z-index: 11;
    transition: all .4s;
}

.social_share_div_row.listfixed {
    position: fixed;
    z-index: 999;
}

.social_share_item_header {
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0;
    text-transform: uppercase;
}

.social_share_item.social_share_item_header {
    padding: 8px 7px;
    margin-bottom: 10px;
    margin-top: -1px;
    border-radius: 5px 0px 0px 0px;
}

/* Social Share section end */

.main_title {
    font-size: 47px;
    font-weight: 600;
    line-height: 0.92;
}

.w_100 {
    width: 100%;
}

.small_title {
    font-weight: 400;
    font-size: 21px;
    letter-spacing: 0;
    line-height: 1.2;
}
@media(max-width: 1600px){
    .content {
        min-height: calc(100vh - 706px);
    }    
}
@media (max-width: 1440px) {
    .content {
        min-height: calc(100vh - 696px);
    }      
    .main_title {
        font-size: 44px;
    }
}

@media (max-width: 1200px) {
    .content {
        min-height: calc(100vh - 686px);
    }     
    .main_title {
        font-size: 40px;
        line-height: 1.1;
    }
}

@media (max-width: 1024px) {
    .main_title {
        font-size: 37px;
    }

    .small_title {
        font-size: 20px;
    }
}

@media (max-width: 820px) {
    .content {
        min-height: calc(100vh - 676px);
    }     
    .c {
        padding: 0 40px;
    }

    .field_row input,
    .field_row textarea,
    .field_row button,
    .field_row .button {
        padding: 12px 15px;
    }

    .main_title {
        font-size: 34px;
    }

    .cEditor p,
    .cEditor ol,
    .cEditor ul,
    .cEditor>div {
        line-height: 1.3;
    }
}
@media (max-width: 740px) {
    .content {
        min-height: calc(100vh - 666px);
    }  
}
@media (max-width: 720px) {

    /* social share begin */
    .social_share_div {
        display: none;
    }

    /* Social Share end */
}
@media(max-width:600px){
    .content {
        min-height: calc(100vh - 655px);
    }     
}
@media(max-width:420px) {
    .c {
        padding: 0 30px;
    }

    .field_row input,
    .field_row textarea,
    .field_row button,
    .field_row .button {
        padding: 10px 15px;
    }

    .main_title {
        font-size: 31px;
    }

    .cEditor h4 {
        font-size: 19px;
    }

    .small_title {
        line-height: 1.1;
    }

    .submit button,
    .submit .button {
        min-width: auto;
        width: 100%;
    }

    .content {
        min-height: 600px;
    }
}

@media(max-width:320px) {
    .main_title {
        font-size: 27px;
    }

    .content {
        min-height: 500px;
    }
}